<template>
    <div class="w-full text-center text-nomad-400 font-thin my-10 mb-60">
        <p>
            <a href="/pages/privacy-policy" target="_blank" class="hover:text-white">Privacy</a> | <a href="/pages/terms" target="_blank" class="hover:text-white">Terms</a>
        </p>
        <p>&copy; 2025 Nomad Lyrics</p>
        <p>
            built with
            <span
                class="icon-heart hover:text-nomad-accent text-sm hover:scale-125 transition-all text-nomad-400"
                target="_blank"
                aria-label="love"
            ></span> by <a href="https://nexainteractives.com" target="_blank" class="font-bold">Nexa Interactives</a>
        </p>
    </div>
</template>

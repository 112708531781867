<template>
    <div class="w-full bg-nomad-accent  text-nomad-900 font-light rounded-lg" v-if="contents">
        <div class="max-w-7xl m-auto py-10">
            <section>
                <img src="https://cdn.nomadlyrics.com/static/artists/UJFpkk4dV0Xes1R6TSPcJzxcG7OyQkLZDc1kPuHY.png"
                    class="w-full lg:rounded-lg " alt="">
                <hr class="border-nomad-900 my-4 w-10 mx-auto" />
                <h1 class="text-3xl font-thin lg:text-4xl my-4 mx-3">{{ contents.title }}</h1>
            </section>

            <section class="mx-3 my-4">
                <p class="text-lg font-light">NomadLyrics is your ultimate destination for music lyrics. Our apps for both
                    iOS and Android platforms offer a seamless music discovery experience. Explore lyrics, discover new
                    songs, and more!</p>
            </section>

            <section class="mx-3  my-4">
                <h2 class="text-3xl font-light lg:text-4xl my-4 ">Key Features:</h2>
                <p>
                <ul class="list-disc list-inside mt-2">
                    <li class="mb-2">Comprehensive Lyrics Database: Access lyrics for thousands of songs across 100s of
                        Somali artists.</li>
                    <li class="mb-2">Discover New Music: Explore trending songs and discover hidden gems we go back way to
                        1950s.</li>
                    <li class="mb-2">User-Friendly Interface: Easy-to-navigate design for an exceptional user experience.
                    </li>
                    <li class="mb-2">Honestly, there's no denying it – we proudly stand as the top Somali app around!</li>
                </ul>
                </p>
            </section>

            <section class="mx-3  my-4">
                <h2 class="text-3xl font-light lg:text-4xl my-4 ">Screenshots:</h2>
                <Carousel v-if="slides" :items-to-show="4" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                    <Slide v-for="slide in slides" :key="slide.id">
                        <div class="w-full rounded-lg overflow-hidden m-2"><img :src="slide.image" :alt="slide.title"
                                :title="slide.title" class="w-full" /></div>
                    </Slide>
                </Carousel>
            </section>

            <section class="mx-3  my-4">
                <h2 class="text-3xl font-light lg:text-4xl my-4 ">Get the Apps:</h2>
                <p class="text-xd">last updated: 04/22/2024</p>
                <p class="text-xd"><Link href="/pages/apps-changelog" class="font-bold my-4">Changelog <span class="icon-external-link"></span></Link></p>
                <p>
                <div class="text-center mt-8 flex flex-col gap-6">
                    <div><a href="https://apps.apple.com/app/apple-store/id1503789749?pt=121219390&ct=NomadLyricsWebsite&mt=8"
                        class="bg-nomad-accent hover:bg-nomad-900 hover:text-white text-nomad-900 py-2 px-4 border-2 border-nomad-900 rounded-full text-lg font-semibold ml-4" target="_blank">Download
                        on the App Store</a></div>
                    <div><a href="https://play.google.com/store/apps/details?id=com.nomadlyrics"
                        class="bg-nomad-accent hover:bg-nomad-900 hover:text-white text-nomad-900 py-2 px-4 border-2 border-nomad-900 rounded-full text-lg font-semibold ml-4" target="_blank">Get
                        it on Google Play</a></div>
                </div>
                </p>
            </section>

            <section class="mx-3  my-4">
                <h2 class="text-3xl font-light lg:text-4xl my-4 ">Why Choose NomadLyrics:</h2>
                <p>
                <ul class="list-disc list-inside mt-2">
                    <li class="mb-2">Lyrics for Every Mood: Whether you're feeling happy, sad, or adventurous, we have lyrics to match your mood.</li>
                    <li class="mb-2">Stay Updated: we don't just give you lyrics, we verify them.</li>
                    <li class="mb-2">User-Friendly: Our apps are designed with you in mind, making it easy to find and enjoy lyrics.</li>
                </ul>
                </p>
            </section>

            <section class="mx-3 my-4">
                <p class="text-lg font-light">Streaming 1.2M+ minutes monthly of Somali music, poetry, interviews, and literature. Keep the platform alive and thriving. Contribute to preserve and celebrate Somali cultural heritage. Join us in connecting enthusiasts and supporting talented artists.</p>
            </section>

            <section class="mx-3 my-4">
                <h2 class="text-3xl font-light lg:text-4xl my-4 ">Advertise with us</h2>
                <p class="my-3">Reach a passionate audience of Somali culture enthusiasts.</p>
                <img src="https://cdn.nomadlyrics.com/static/artists/Yu81iGGdXGUkcfkvRb7K357vU2sNVotxXh4NEgHx.png"
                    class="w-full lg:rounded-lg " alt="Advertise with us">
            </section>

            <section class="mx-3  my-4">
                <p class="my-3">Reach us at: <a href="mailto:hello@nomadlyrics.com" class="font-bold">hello@nomadlyrics.com</a></p>
            </section>
            

        </div>
    </div>
</template>
<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { Link } from "@inertiajs/vue3";
export default {
    props: {
        contents: {
            type: Object
        }
    },
    data() {
        return {
            slides: [
                {
                    id: 1,
                    image: 'https://cdn.nomadlyrics.com/static/artists/p8Q5ileEbYi85EaauGmHCOxAhmpHiOZpYknyqhRy.png',
                    title: 'Discover New Music and Artists'
                },
                {
                    id: 2,
                    image: 'https://cdn.nomadlyrics.com/static/artists/jUxoSyd1FiiUwXG1qVONLcmux4j3dP971cPRJLvT.png',
                    title: 'Events and Concerts'
                },
                {
                    id: 3,
                    image: 'https://cdn.nomadlyrics.com/static/artists/NSJTY0UVKQzZtiq3cv8BNP0NA36R56Pbbm3kfMIp.png',
                    title: 'Your favorites artists and more'
                },
                {
                    id: 4,
                    image: 'https://cdn.nomadlyrics.com/static/artists/QYOq5A7Q2j5i5jflBYI86kJKY2ptp19IKiWRPPm1.png',
                    title: 'Podcasts'
                }
            ],
            breakpoints: {
                // 700px and up
                100: {
                    itemsToShow: 1.2,
                    snapAlign: "center",
                },
                700: {
                    itemsToShow: 2.2,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Link
    }
}
</script>
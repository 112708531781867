<template>
    <Container class="w-full">
        <div class="w-full">
            <div class="w-full">
            <form @submit.prevent="submit" class="grid gap-4">
                <div>
                    <TextField
                        placeholder="scholar name"
                        v-model:text="form.name"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="name in arabic"
                        v-model:text="form.name_arabic"
                    />
                </div>
                <div><ImageUploader v-model="form.image" :image="form.image" /></div>
               
                <div class="block w-full mb-10">
                    <textarea v-model="form.bio" class="text-black rounded-lg w-full h-64" ></textarea>
                </div>
                <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Update</button></div>
            </form>
            <h6 v-if="form.hasErrors" class="text-nomad-accent my-2">{{ form.errors.name }}</h6>
            <pre class="text-white">{{ form }}</pre>
        </div>
        </div>
    </Container>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'


import TextField from "@/Pages/Backstage/Components/TextField.vue";
import ImageUploader from "@/Pages/Backstage/Components/ImageUploader.vue";
import Container from "@/Pages/Backstage/Container.vue";
import { usePage, useForm } from "@inertiajs/vue3";
import { onMounted, ref } from "vue";
const data = usePage().props.scholar;

const form = useForm({
    name: data.name,
    name_arabic: data.name_arabic,
    image: data.image,
    bio: data.bio,
    _method: 'PUT'
});

onMounted(() => {
    
});

function submit() {
    form.put('/backstage/scholars/' + data.id)
}
</script>

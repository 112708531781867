<template>
    <Container class="w-full">
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full">
            <form @submit.prevent="submit" class="grid gap-4">
                <div>
                    <TextField
                        placeholder="resource title"
                        v-model:text="form.title"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="resource title arabic"
                        v-model:text="form.title_translation.arabic"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="resource title english"
                        v-model:text="form.title_translation.english"
                    />
                </div>
                <div>
                    <textarea
                        placeholder="Description"
                        v-model="form.description"
                        class="rounded-md w-full"
                    ></textarea>
                </div>
                <div>
                    <Selector
                        type="scholars"
                        v-model="form.scholar"
                        placeholder="select scholar"
                    />
                </div>
                <div>
                    <Selector
                        type="topics"
                        v-model="form.topics"
                        placeholder="select topic"
                    />
                </div>
                <div>
                    <input type="number" min="0" v-model="form.ordering" />
                </div>
                <div class="block w-full mb-10">
                   <Block v-model:blocks="form.translation" />
                </div>
                <div>
                    <Media :resource_id="resources.id" :media="resources.media" v-model="form.media" />
                </div>
                <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Update</button></div>
            </form>
            <h6 v-if="form.hasErrors" class="text-nomad-accent my-2 rounded-md p-2 border border-nomad-red">
                Please fill all the required fields
                <ul>
                    <li v-for="(error, name) in form.errors" class="text-nomad-red">{{ name + ': ' + error }}</li>
                </ul>
            </h6>
            <pre class="text-white">{{ form }}</pre>
        </div>
    </Container>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'


import Container from "@/Pages/Backstage/Container.vue";
import TextField from "@/Pages/Backstage/Components/TextField.vue";
import Selector from "@/Pages/Backstage/Components/Selector.vue";
import Block from "@/Pages/Backstage/Components/Block.vue";
import Media from "@/Pages/Backstage/Components/Media.vue";
import { useForm, usePage, router, Link } from "@inertiajs/vue3";
import { ref, onMounted } from "vue";

const { resources } = usePage().props;

const form = useForm({
    title: resources.title,
    title_translation: resources.title_translation ?? { arabic: null, english: null },
    scholar: resources.scholar,
    topics: resources.topic,
    description: resources.description,
    source_url: resources.source_url,
    translation: resources.translation,
    ordering: resources.ordering,
    media: resources.media,
    _method: 'PUT'
});

onMounted(() => {
    console.log(usePage().props);
});

function submit() {
    form.put('/backstage/resources/' + resources.id, {preserveScroll: true,})
}
</script>

<template>
    <div class="">
        <div class="mt-2 text-center">
            <a href="https://www.tiktok.com/@nomadlyrics" target="_blank" class="bg-nomad-100 flex  w-full">
                <img src="/assets/tiktok-logo.png" alt="follows us" class="h-10 m-auto py-2">
            </a>
        </div>
        <div class="grid grid-cols-2 gap-4 text-center my-3 mx-2">
            <div class="text-center">
                <a
                    href="https://play.google.com/store/apps/details?id=com.nomadlyrics"
                    class="inline-block focus:outline-none"
                    target="_blank"
                    ><img
                        src="@/assets/google-play.png"
                        class="w-full max-w-xs"
                        alt="Get Nomad Lyrics on Google Play"
                /></a>
            </div>
            <div class="text-center">
                <a
                    href="https://apps.apple.com/app/apple-store/id1503789749?pt=121219390&ct=NomadLyricsWebsite&mt=8"
                    class="inline-block focus:outline-none"
                    target="_blank"
                    ><img
                        src="@/assets/app-store.png"
                        class="w-full max-w-xs "
                        alt="Get Nomad Lyrics on App Store"
                /></a>
            </div>
        </div>
        
    </div>
</template>

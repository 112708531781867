<template>
    <div>
        <div class="flex gap-3 overflow-x-auto">
            <Link href="/backstage/artists" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Artists</Link>
            <Link href="/backstage/songs" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Songs</Link>
            <Link href="/backstage/albums" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Albums</Link>
            <Link href="/backstage/events" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Events</Link>
            <Link href="/backstage/pages" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Pages</Link>
            <Link href="/backstage/playlist" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Playlist</Link>
            <Link href="/backstage/story" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Stories</Link>
            <Link href="/backstage/scholars" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Scholars</Link>
            <Link href="/backstage/topics" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Topics</Link>
            <Link href="/backstage/resources" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Resources</Link>
            
        </div>
        <div class="w-full">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { Link } from "@inertiajs/vue3";
export default {
    components: {
        Link,
    },
};
</script>
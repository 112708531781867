<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
    blocks: {
        type: Array,
        required: false,
        default: () => []
    }
});

const emit = defineEmits(['update:blocks']);

const localBlocks = ref(Array.isArray(props.blocks) ? [...props.blocks] : []);

watch(() => props.blocks, (newBlocks) => {
    localBlocks.value = Array.isArray(newBlocks) ? [...newBlocks] : [];
}, { deep: true });

const updateBlocks = () => {
    console.log(localBlocks.value);
    emit('update:blocks', localBlocks.value);
};

const addBlock = (language) => {
    localBlocks.value.push({ language, type: 'text', content: '' });
    emit('update:blocks', localBlocks.value);
};

const removeBlock = (index) => {
    localBlocks.value.splice(index, 1);
    emit('update:blocks', localBlocks.value);
};
</script>

<template>
    <div>
        <div v-for="(block, index) in localBlocks" :key="index" class="mb-4 border border-nomad-600 bg-nomad-800 p-4 rounded-md">
            <h6 class="text-white my-2">{{ block.language === 'arabic' ? 'Arabic' : block.language === 'somali' ? 'Somali' : 'English' }}</h6>
            <textarea 
                v-model="block.content"
                @input="updateBlocks"
                class="w-full p-2 border rounded-md"
                :dir="block.language === 'arabic' ? 'rtl' : 'ltr'"
                :placeholder="block.language === 'arabic' ? 'Arabic...' : block.language === 'somali' ? 'Somali...' : 'English...'"
            ></textarea>
            <button type="button" @click="removeBlock(index)" class="text-red-500">Remove</button>
        </div>
        <div class="flex gap-4">
            <button 
                type="button" 
                class="bg-nomad-accent rounded-md p-4 text-black" 
                @click="addBlock('somali')"
            >
                Add Somali
            </button>
            <button 
                type="button" 
                class="bg-nomad-accent rounded-md p-4 text-black" 
                @click="addBlock('arabic')"
            >
                Add Arabic
            </button>
            <button 
                type="button" 
                class="bg-nomad-accent rounded-md p-4 text-black" 
                @click="addBlock('english')"
            >
                Add English
            </button>
        </div>
    </div>
</template>
<style scoped>
textarea[dir="rtl"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, sans-serif;
}

textarea[dir="ltr"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, sans-serif;
}
</style>
<script setup>
import { computed } from 'vue';
import { useForm, router, usePage } from "@inertiajs/vue3";
import axios from "axios";
const props = defineProps({ image: String })
const form = useForm({
    image: null,
    imagepath: "artists",
    component: usePage().component,
});

let shouldUpload = false;

function uploadImage($event) {
    let data = new FormData();
    data.append("image", $event.target.files[0]);
    data.append("imagepath", "artists");
    data.append("component", usePage().component);

    axios.post("/api/backstage/image-uploader", data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        onUploadProgress: (progressEvent) => {
            form.progress = {
                percentage: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            };
        }
    })
        .then((response) => {
            emit("update:modelValue", '/' + response.data.image);
            form.progress = null;
        })
        .catch((error) => console.log(error));
}

const imageUrl = computed(() => {
    if (!props.image) return null;
    return props.image.includes('.com') ? props.image : `https://cdn.nomadlyrics.com${props.image}`;
});

const emit = defineEmits(["update"]);
</script>

<template>
    <div>
        <label class="block text-nomad-100 text-lg font-thin">Upload Image</label>
        {{ props }}
        <input type="file" @input="uploadImage"
            class="block text-lg font-thin overflow-hidden text-nomad-300 border border-gray-300 rounded-lg cursor-pointer bg-nomad-900 focus:outline-none" />
        <progress class="bg-nomad-accent text-xs leading-none py-1 text-center text-white" v-if="form.progress"
            :value="form.progress.percentage" max="100">
            {{ form.progress.percentage }}%
        </progress>
        <img :src="imageUrl" class="w-48 my-3 rounded-md" v-if="imageUrl" alt="image">
    </div>
</template>

<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/resources/create"
                class="text-nomad-accent rounded-md py-2 px-3 bg-nomad-700 hover:bg-nomad-600"
                >Create</Link
            >
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>

        <div class="w-full">
            <ul v-if="resources && resources.data" class="grid gap-3">
                <li
                    v-for="resource in resources.data"
                    :key="resource.id"
                    class="flex flex-col lg:flex-row gap-4 items-center p-3 bg-nomad-800 rounded-3xl text-white"
                >
                    <div class="flex-auto">
                        <Link :href="'/backstage/resources/' + resource.id + '/edit'">
                            <h2 class="text-xl text-nomad-50">
                                {{ resource.title }}
                            </h2>
                            <span class="text-nomad-accent mr-2" v-if="resource.scholar">{{ resource.scholar.name }}</span>
                        </Link>
                    </div>
                    <button  @click.prevent="() => deleteResource(resource.id)" class="icon-x rounded-full bg-nomad-red text-white p-1"></button>
                </li>
            </ul>
        </div>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage } from "@inertiajs/vue3";
const { resources } = usePage().props;
function deleteResource(id) {
    if (confirm("Are you sure you want to delete this resource?")) {
        console.log(id)
        axios.delete(`/backstage/resources/${id}`).then(() => {
            // refresh the page
            location.reload();
        });
    }
}
</script>
<template>
    <div class="">
        <div class="flex max-w-md text-white">
            <input type="file" @change="handleFileChange" ref="audioInput" accept="audio/*" class=" w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
      file:text-sm file:font-semibold
      file:bg-violet-50 file:text-violet-700
      hover:file:bg-violet-100">
            <button @click="uploadAudio" type="button"
                class="bg-nomad-accent text-black font-medium px-3 py-2 rounded-lg" :disabled="uploading"><span class="icon-upload-cloud text-black mx-3" v-if="uploading"></span>Upload</button>
        </div>
        <div v-if="error">{{ error }}</div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            audioFile: null,
            uploading: false,
            error: null,
        };
    },
    methods: {
        handleFileChange(event) {
            this.audioFile = event.target.files[0];
        },
        async uploadAudio() {

            const formData = new FormData();
            formData.append('audio', this.audioFile);

            this.error = null
            this.uploading = true

            await axios.post('/api/backstage/audio-uploader', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.$emit('update:modelValue', response.data.audio_path);
                this.error = null
                this.uploading = false
                this.$refs.audioInput.value = '';
            }).catch((error) => {
                this.error = error
                this.uploading = false
                this.$refs.audioInput.value = '';
            });

            // Emit the URL to the parent component


        }
    }
}
</script>
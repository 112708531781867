<template>
    <div class="w-full bg-nomad-800 border border-nomad-700 p-8 rounded-md my-4">
        <AudioUploader v-model="form.audio" />
        <ul class="mt-4" v-if="form.contents && form.contents.length > 0">
            <li>Pending</li>
            <li v-for="(value, index) in form.contents" class="text-nomad-accent rounded-md bg-nomad-900 p-4 flex flex-cols"><span class="flex-auto text-nomad-accent">{{ value.file_path }}</span> <button type="button" @click="() => removeContent(index)" class="icon-x text-white bg-nomad-red rounded-full text-md font-bold p-1"></button></li>
        </ul>
        <ul class="mt-4" v-if="props.media && props.media.length > 0">
            <li class="text-white">Attached</li>
            <li v-for="md in props.media" class="text-nomad-accent rounded-md bg-nomad-900 p-4 flex flex-cols"><span class="flex-auto text-nomad-accent">{{ md.file_path }}</span> <button type="button" @click="() => detachMedia(md.id)" class="icon-x text-white bg-nomad-red rounded-full text-md font-bold p-1"></button></li>
        </ul>
    </div>
</template>
<script setup>
import AudioUploader from "@/Pages/Backstage/Components/AudioUploader.vue";
import { useForm } from "@inertiajs/vue3";
import { defineProps, defineEmits, ref, watch } from "vue";

// props
const props = defineProps({ resource_id: Number, media: Array });
const form = useForm({
    contents: [],
    audio: null,
});

// emits
const emits = defineEmits(["update:modelValue"]);


// watch audio changes.
watch(() => form.audio, (audio) => {
    audio = {"file_path":audio, "media_type": "audio", "resource_id": props.resource_id}
    form.contents = [ ...form.contents, audio ]
})

watch(() => form.contents, (contents) => {
    emits("update:modelValue", contents)
})

const removeContent = (index) => {
    form.contents.splice(index, 1)
}

const detachMedia = (media_id) => {
    axios.delete(`/api/backstage/media-detach/${media_id}`)
    .then((response) => {
        console.log(response)
        // reload the page.
        location.reload()
    })
    .catch((error) => {
        console.log(error)
    })
}

</script>
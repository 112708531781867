<template>
    <Container class="w-full">
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full">
            <form @submit.prevent="submit" class="grid gap-4">
                <div>
                    <TextField
                        placeholder="scholar name"
                        v-model:text="form.name"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="name in arabic"
                        v-model:text="form.name_arabic"
                    />
                </div>
                <div><ImageUploader v-model="form.image" /></div>
                <div class="block w-full mb-10">
                    <textarea v-model="form.bio" class="text-black w-full h-64 rounded-lg" ></textarea>
                </div>
                <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Create</button></div>
            </form>
            <h6 v-if="form.hasErrors" class="text-nomad-accent my-2">{{ form.errors.name }}</h6>
            <pre class="text-white">{{ form }}</pre>
        </div>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import TextField from "@/Pages/Backstage/Components/TextField.vue";
import ImageUploader from "@/Pages/Backstage/Components/ImageUploader.vue";

import { useForm, usePage, router, Link } from "@inertiajs/vue3";
import { ref, onMounted } from "vue";

const form = useForm({
    name: null,
    name_arabic: null,
    image: null,
    bio: null
});

onMounted(() => {
    console.log(usePage().props);
});

function submit() {
    form.post('/backstage/scholars')
}
</script>
